

@font-face {
  font-family: "Century Gothic";
  src: url("./utils/GOTHICB.TTF") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Avenir Heavy";
  src: url("./utils/Avenir Heavy.ttf") format("truetype");
  font-weight: 500;
}