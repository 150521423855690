.markdown * {
  text-align: left !important;
  line-height: 32px !important;
  letter-spacing: 0.5px !important;
  /* font-style: normal !important; */
  font-family: 'Archivo', sans-serif !important;
}
.markdown p {
  font-size: 16px !important;
  margin-bottom: 10px;
}
.markdown h1 * {
  font-size: 42px !important;
}
.markdown h2 * {
  font-size: 20px !important;
}
.markdown h2 {
  line-height: 28px !important;
}
.markdown h3 * {
  font-size: 18px !important;
}
.markdown h3 {
  line-height: 25px !important;
}

.markdown h4 * {
  font-size: 16px !important;
}
.markdown h4 {
  line-height: 23px !important;
}

.markdown h5 * {
  font-size: 16px !important;
}

.markdown h5 {
  line-height: 23px !important;
}

.markdown h6 * {
  font-size: 14px !important;
}

.markdown h6 {
  line-height: 21px !important;
}
.markdown p:empty {
  height: 26px !important;
}

.markdown blockquote:empty {
  height: 15px !important;
}
.markdown ul {
  font-size: 18px !important;
  padding-left: 20px !important;
  font-family: 'Archivo', sans-serif !important;
}

.markdown a {
  color: #009245 !important;
  text-decoration: none;
  max-width: 100%;
  display: inline;
  word-wrap: break-word;
  font-family: 'Archivo', sans-serif !important;
}

.markdown blockquote {
  border-left: 4px solid #009245;
  padding-left: 10px;
  margin-left: 0;
  font-family: 'Archivo', sans-serif !important;
}

.editor * {
  text-align: left !important;
  /* line-height: 27px !important; */
  letter-spacing: 0.04em !important;
  /* font-style: normal !important; */
  font-family: 'Archivo', sans-serif !important;
}
.editor p {
  font-size: 16px !important;
}
.editor h1 * {
  font-size: 42px !important;
}
.editor h2 * {
  font-size: 20px !important;
}
.editor h2 {
  line-height: 28px !important;
}
.editor h3 * {
  font-size: 18px !important;
}
.editor h3 {
  line-height: 25px !important;
}

.editor h4 * {
  font-size: 16px !important;
}
.editor h4 {
  line-height: 23px !important;
}

.editor h5 * {
  font-size: 16px !important;
}

.editor h5 {
  line-height: 23px !important;
}
 
.editor h6 * {
  font-size: 14px !important;
}

.editor h6 {
  line-height: 21px !important;
}
.editor p:empty {
  height: 15px !important;
}
.editor ul {
  font-size: 18px !important;
  padding-left: 20px !important;
  font-family: 'Archivo', sans-serif !important;
}

.editor a {
  color: #009245 !important;
  text-decoration: none;
  max-width: 100%;
  display: inline;
  word-wrap: break-word;
  font-family: 'Archivo', sans-serif !important;
}

.editor blockquote {
  border-left: 4px solid #009245;
  padding-left: 10px;
  margin-left: 0;
  font-family: 'Archivo', sans-serif !important;
}

iframe {
  width: 100%;
  height: 100%;
  min-height: 500px;
}

@media (max-width: 640px) {
  iframe {
    min-height: 250px;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  iframe {
    min-height: 290px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  iframe {
    min-height: 310px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  iframe {
    min-height: 320px;
  }
}

@media (min-width: 1200px) {
  iframe {
    min-height: 350px;
  }
}

.toolbar-remove {
  display: none !important;
}

.public-DraftStyleDefault-ltr{
  margin: 0 !important; 
}

.rdw-editor-main {
  padding: 0px 15px 0px 15px !important;
}
